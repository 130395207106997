import { bindable, bindingMode, inject } from 'aurelia-framework';
import { I18N }                          from 'aurelia-i18n';
import { BaseListViewModel }             from 'base-list-view-model';
import { FilterFormSchema }              from 'modules/management/concessions/concessions-tree/lot-constructions/listing/filter-form-schema';
import { LotConstructionsRepository }    from 'modules/management/concessions/concessions-tree/lot-constructions/services/repository';
import { ConstructionType }              from 'modules/management/models/construction-type';
import { LotConstructionType }           from 'modules/management/models/lot-construction-type';
import { AppContainer }                  from 'resources/services/app-container';
import { Downloader }                    from 'resources/services/downloader';

@inject(AppContainer, LotConstructionsRepository, FilterFormSchema, I18N, Downloader)
export class ListLotConstructions extends BaseListViewModel {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) listingId;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterModel;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterSchema;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) filterFormIsDirty;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) displayTree;
    @bindable selectedType;
    @bindable newRecordRoute;
    @bindable recordRouteParams;

    showDatatable         = false;
    lotConstructionTypeId = null;
    constructionTypeSlug;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param filterFormSchema
     * @param i18N
     * @param downloader
     */
    constructor(appContainer, repository, filterFormSchema, i18N, downloader) {
        super(appContainer);
        this.repository       = repository;
        this.filterFormSchema = filterFormSchema;
        this.i18N             = i18N;
        this.downloader       = downloader;
    }

    /**
     * Binds the view-model to the view.
     * Initializes the data required for the view.
     */
    bind() {
        this.initializeData();
    }

    /**
     * Handles changes to the selectedType property.
     * Refreshes the datatable if the selected type changes.
     *
     * @param {string} newValue - The new value of selectedType.
     * @param {string} oldValue - The old value of selectedType.
     */
    selectedTypeChanged(newValue, oldValue) {
        if (newValue !== oldValue && newValue) {
            this.refreshDatatable();
        }
    }

    /**
     * Toggles the display of the tree view.
     */
    toggleTree() {
        this.displayTree = !this.displayTree;
    }

    /**
     * Initializes the data required for the view.
     * Sets the lot construction type ID, filter schema, listing ID, header title, and filter model.
     * Defines the datatable configuration.
     */
    initializeData() {
        this.lotConstructionTypeId = this.resolveLotConstructionTypeId();
        this.listingId             = `management-concessions-lot-constructions-listing-${this.lotConstructionTypeId}`;
        this.constructionTypeSlug  = this.getLotConstructionTypeInfo('slug');

        this.headerTitle       = `listing.management.concessions.lot-constructions-${this.constructionTypeSlug}`;
        this.newRecordRoute    = `management.concessions.lot-constructions-${this.constructionTypeSlug}.create`;
        this.recordRouteParams = {
            id:                       this.getLotConstructionTypeInfo('constructionTypeId'),
            slug:                     this.constructionTypeSlug,
            title:                    this.getLotConstructionTypeInfo('title'),
            lot_construction_type_id: this.lotConstructionTypeId,
        };

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this, this.filterModel.parent_id, this.filterModel.parent_key);

        this.defineDatatable();
    }

    /**
     * Refreshes the datatable by re-initializing the data.
     * Uses a setTimeout to ensure the datatable is re-rendered properly.
     */
    refreshDatatable() {
        this.showDatatable = false;

        // ! HFM: For some reason if i don't use setTimeout here, the datatable is not re-rendered properly
        // ! and the columns/selected columns are not displayed correctly. This is a workaround and it should be temporary.
        setTimeout(() => {
            this.initializeData();
        }, 0);
    }

    /**
     * Resolves the lot construction type ID based on the selected type.
     *
     * @returns {number|null} The lot construction type ID or null if not found.
     */
    resolveLotConstructionTypeId() {
        const typeMapping = {
            'OE': LotConstructionType.OE,
            'OA': LotConstructionType.OA,
            'OH': LotConstructionType.OH,
        };

        for (const key in typeMapping) {
            if (this.selectedType.includes(`-${key}`)) {
                return typeMapping[key];
            }
        }

        return null;
    }

    /**
     * Defines the configuration for the datatable.
     * Sets the repository, actions, permissions, and columns for the datatable.
     */
    defineDatatable() {
        this.datatable = {
            repository:        this.repository,
            show:              {
                action:  (row) => this.appContainer.router.navigateToRoute(`management.concessions.lot-constructions-${this.constructionTypeSlug}.view`, { id: row.id, type: this.recordRouteParams, filterModel: this.filterModel }),
                visible: (row) => this.hasPermissions(['management.concessions.concessions.lot-constructions.manage', 'management.concessions.concessions.lot-constructions.view']),
            },
            edit:              {
                action:  (row) => this.appContainer.router.navigateToRoute(`management.concessions.lot-constructions-${this.constructionTypeSlug}.edit`, { id: row.id, type: this.recordRouteParams, filterModel: this.filterModel  }),
                visible: (row) => this.hasPermissions(['management.concessions.concessions.lot-constructions.manage', 'management.concessions.concessions.lot-constructions.edit']),
            },
            destroy:           {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.hasPermissions(['management.concessions.concessions.lot-constructions.manage', 'management.concessions.concessions.lot-constructions.delete']),
            },
            destroyed:         () => this.appContainer.eventAggregator.publish('reload-fancy-tree', { id: 'concessions-concessions-trees-listing' }),
            destroyedSelected: () => this.appContainer.eventAggregator.publish('reload-fancy-tree', { id: 'concessions-concessions-trees-listing' }),
            options:           [],
            selectable:        true,
            destroySelected:   true,
            sorting:           {
                column:    0,
                direction: 'asc',
            },
            columns:           this.getTableColumns(),
        };

        this.showDatatable = true;
    }

    /**
     * Checks if the authenticated user has the specified permissions.
     *
     * @param {Array<string>} permissions - The list of permissions to check.
     * @returns {boolean} True if the user has all the specified permissions, false otherwise.
     */
    hasPermissions(permissions) {
        return this.appContainer.authenticatedUser.can(permissions);
    }

    /**
     * Gets the columns configuration for the datatable.
     *
     * @returns {Array<Object>} The columns configuration.
     */
    getTableColumns() {
        const isOE = this.lotConstructionTypeId === LotConstructionType.OE;

        return [
            {
                data:  'element',
                name:  'construction_translations.name',
                title: this.getLotConstructionTypeInfo('title'),
            },
            {
                data:   'number',
                name:   'lot_constructions.number',
                title:  'form.field.number',
                hidden: isOE,
            },
            {
                data:   'designation',
                name:   'lot_construction_translations.designation',
                title:  'form.field.designation',
                hidden: isOE,
            },
            {
                data:   'number_designation',
                name:   'lot_construction_translations.designation',
                title:  `${this.i18N.tr('form.field.number')} - ${this.i18N.tr('form.field.designation')}`,
                hidden: !isOE,
            },
            {
                data:   'concession',
                name:   'concession_translations.description',
                title:  'form.field.concession',
                hidden: isOE,
            },
            {
                data:   'highway',
                name:   'highway_translations.designation',
                title:  'form.field.highway',
                hidden: isOE,
            },
            {
                data:   'entity',
                name:   'entity_translations.name',
                title:  'form.field.entity',
                hidden: isOE,
            },
            {
                data:  'code',
                name:  'lot_constructions.code',
                title: 'form.field.code',
            },
            {
                data:            'created_at',
                name:            'lot_constructions.created_at',
                title:           'form.field.created-at',
                display:         false,
                valueConverters: [
                    {
                        name: 'dateTimeFormat',
                    },
                ],
            },
            {
                data:    'created_by',
                name:    'users.name',
                title:   'form.field.created-by',
                display: false,
            },
        ];
    }

    /**
     * Gets the title, abbreviation, or construction type ID based on the lot construction type ID.
     *
     * @param {string} key - The key to retrieve ('title', 'abbreviation', 'constructionTypeId').
     * @returns {string} The corresponding value for the lot construction type.
     */
    getLotConstructionTypeInfo(key) {
        const config = {
            [LotConstructionType.OE]: {
                title:                 'form.field.element',
                slug:                  'oe',
                constructionTypeId:    ConstructionType.OE_ELEMENTS,
            },
            [LotConstructionType.OA]: {
                title:                 'form.field.oa-type',
                slug:                  'oa',
                constructionTypeId:    ConstructionType.OA_TYPES,
            },
            [LotConstructionType.OH]: {
                title:                 'form.field.oh-type',
                slug:                  'oh',
                constructionTypeId:    ConstructionType.OH_TYPES,
            },
        };

        return (config[this.lotConstructionTypeId] && config[this.lotConstructionTypeId][key]) || '';
    }
}
