import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class OEDatasRepository extends BaseRepository {
    baseUrl = 'management/concessions/concessions/lot-constructions/oe-data';

    /**
     * Filter data from lot construction
     *
     * @param lotConstructionId
     *
     * @returns {*}
     */
    filterData(lotConstructionId) {
        return this.httpClient.post(`${this.baseUrl}/${lotConstructionId}/filter-data`);
    }

    /**
     * Search and return PK values
     *
     * @param criteria
     *
     * @returns {*}
     */
    searchPKValues(criteria) {
        return this.httpClient.post(`${this.baseUrl}/search-pk-values`, criteria);
    }
}
