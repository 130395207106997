import { DialogService }                     from 'aurelia-dialog';
import { bindable, inject }                  from 'aurelia-framework';
import { I18N }                              from 'aurelia-i18n';
import { BaseListViewModel }                 from 'base-list-view-model';
import { LotConstructionElementsFormSchema } from 'modules/management/concessions/concessions-tree/lot-constructions/elements/form-schema';
import { DefaultDeleteResourceDialog }       from 'resources/elements/html-elements/dialogs/default-delete-resource-dialog';
import { AppContainer }                      from 'resources/services/app-container';

@inject(AppContainer, I18N, DialogService, LotConstructionElementsFormSchema)
export class ListLotConstructionElements extends BaseListViewModel {

    listingId                               = 'management-concessions-concessions-lot-constructions.oe-data-component-listing';
    lotConstructionElementsFormSchemaFormId = 'management-concessions-concessions-lot-constructions.oe-data-component-form';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable datatable;
    @bindable model;

    records;

    /**
     * Constructor
     *
     * @param appContainer
     * @param i18N
     * @param dialogService
     * @param lotConstructionElementsFormSchema
     */
    constructor(appContainer, i18N, dialogService, lotConstructionElementsFormSchema) {
        super(appContainer);

        this.i18N                              = i18N;
        this.dialogService                     = dialogService;
        this.lotConstructionElementsFormSchema = lotConstructionElementsFormSchema;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.revisions.manage',
            'management.specifications.bulletins.revisions.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.records                       = params.elements;
        this.lot_construction_type_id      = params.lot_construction_type_id;
        this.readonly                      = params.readonly;
        this.filterModel                   = this.lotConstructionElementsFormSchema.model(this);
        this.lotConstructionElementsSchema = this.lotConstructionElementsFormSchema.schema(this, this.readonly);

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:          {
                search: (criteria) => this.search(criteria),
            },
            actionsContextMenu:  false,
            showOptionsDropdown: false,
            showResultsPerPage:  false,
            showPagination:      false,
            actions:             [
                {
                    icon:    'icon-trash',
                    action:  (row, index) => this.destroyRecord(index),
                    tooltip: 'form.button.delete',
                    visible: (row) => !this.readonly,
                },
            ],
            options:             [],
            buttons:             [],
            selectable:          false,
            sorting:             {
                column:    0,
                direction: 'asc',
            },
            columns:             [
                {
                    data:       'name',
                    name:       'bulletin_revisions.revision_number',
                    title:      'form.field.name',
                    searchable: false,
                    orderable:  false,
                },
                {
                    data:       'type',
                    name:       'bulletin_revisions.effective_date',
                    title:      'form.field.type',
                    searchable: false,
                    orderable:  false,
                },
            ],
        };
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        let records = this.records;

        if (criteria.search.value !== null && criteria.search.value !== '') {
            let regExp = new RegExp(criteria.search.value, 'gi');

            records = this.records.filter((record) => {
                return ('' + record.name).match(regExp)
                    || ('' + record.type).match(regExp);
            });
        }

        return Promise.resolve({
            data:            records,
            recordsTotal:    this.records.length,
            recordsFiltered: records.length,
        });
    }

    /**
     * Requests confirmation to destroy the selected record
     *
     * @param index
     */
    destroyRecord(index) {
        this.dialogService.open({
                viewModel: DefaultDeleteResourceDialog,
                model:     {},
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.destroy(index).then((response) => {
                        this.appContainer.notifier['successNotice'](response.message);

                        this.datatable.instance.reload();
                    });
                }
            });
    }

    /**
     * Destroy the selected record
     *
     * @param index
     */
    destroy(index) {
        return new Promise((resolve, reject) => {
            this.records.splice(index, 1);

            this.records.forEach((record, number) => record.code = number + 1);

            resolve({
                status:  true,
                message: this.i18N.tr('messages.selected-deletion.succeeded'),
            });
        });
    }
}
